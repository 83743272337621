// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Index.vue'),
      children: [
        {
          path: '',
          alias: '/home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: '/comercio-omnicanal',
          component: () => import('@/views/comercio-omnicanal/Index.vue'),
        },
        {
          path: '/punto-de-venta',
          component: () => import('@/views/punto-de-venta/Index.vue'),
        },
        {
          path: '/gestion-de-pedidos',
          component: () => import('@/views/gestion-de-pedidos/Index.vue'),
        },
        {
          path: '/control-de-inventarios',
          component: () => import('@/views/control-de-inventarios/Index.vue'),
        },
        {
          path: '/gestion-de-envios',
          component: () => import('@/views/gestion-de-envios/Index.vue'),
        },
        {
          path: '/evaluacion-y-mejora',
          component: () => import('@/views/evaluacion-y-mejora/Index.vue'),
        },
        {
          path: '/contact-us',
          component: () => import('@/views/contact-us/Index.vue'),
        },
        {
          path: 'partners',
          component: () => import('@/views/partners/Index.vue'),
        },
        {
          path: '*',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
