// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {      
        //Amarillo
        primary: '#F2C714', 
        secondary: '#01263A',
        accent: '#428bca',
        gradient: '#F2C714',

        "layout-appbar__appbar": "#01263A",
        // "layout-appbar__appbar": "#FFFFFF",
      },
      dark: {
        primary: '#F2C714', 
        secondary: '#01263A',
        accent: '#428bca',
        gradient: '#F2C714',
        
      },
    },
  },
})
